import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/component/home',
    title: 'Home',
    icon: 'mdi mdi-home',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/component/home',
        title: 'Home',
        icon: 'fa fa-home',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },

    ]
  },
  {
    path: '/component/user',
    title: 'People',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/component/user',
        title: 'Users',
        icon: 'fa fa-user',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
      ]
      },
      {
        path: '/component/usergroup',
        title: 'User Groups',
        icon: 'fa fa-group',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/component/saleshome',
    title: 'Sales',
    icon: 'mdi mdi-cart',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/component/saleshome',
        title: 'Sales Home',
        icon: 'fa fa-home',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/customers',
        title: 'Customers',
        icon: 'fa fa-users',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/discounts',
        title: 'Discounts',
        icon: 'fa fa-sort-amount-desc',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/salesOrder',
        title: 'Sales Order',
        icon: 'fa fa-file-text',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/invoices',
        title: 'Invoices',
        icon: 'fa fa-file-text',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/inventoryStatus',
        title: 'Inventory Status',
        icon: 'fa fa-archive',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/component/transfers',
    title: 'Logistics',
    icon: 'mdi mdi-widgets',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/component/transfers',
        title: 'Transfers',
        icon: 'fa fa-truck',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/cslog',
        title: 'CS Log',
        icon: 'fa fa-building',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/inventoryLogist',
        title: 'Inventory',
        icon: 'fa fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/purchasing',
        title: 'Purchasing',
        icon: 'fa fa-shopping-cart',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/containers',
        title: 'Containers',
        icon: 'fa fa-bank',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/deliveries',
        title: 'Deliveries',
        icon: 'fa fa-paper-plane',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/warehouse',
        title: 'Warehouse',
        icon: 'fa fa-bank',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/stockAdjustment',
        title: 'Stock Adjustment',
        icon: 'fa fa-pencil-square',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/deliveryTeams',
        title: 'Delivery Teams',
        icon: 'fa fa-paper-plane',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/component/productsdefinitions',
    title: 'Products',
    icon: 'mdi mdi-sitemap',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/component/productsdefinitions',
        title: 'Product Definitions',
        icon: 'fa fa-puzzle-piece',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            
        ]
      },
      {
        path: '/component/productcategories',
        title: 'Product Categories',
        icon: 'fa fa-sitemap',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/suppliers',
        title: 'Suppliers',
        icon: 'fa fa-institution',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/manufacturers',
        title: 'Manufacturers',
        icon: 'fa fa-university',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/addimage',
        title: 'Add Image for CSV',
        icon: 'fa fa-camera-retro',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/discontinuecsv',
        title: 'Discontinue from CSV',
        icon: 'fa fa-flag',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
     
    ]
  },
  {
    path: '/component/reportsr',
    title: 'Reports',
    icon: 'mdi mdi-file-excel',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
    ]
  },
];
